import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@app/classes';
import { AnalyticEvents } from '@app/enums';
import { SetupGuideStep } from '@app/models/company/setup-guide-step.model';
import { SetupGuide } from '@app/models/company/setup-guide.model';
import { SetupGuideService } from '@app/modules/company/services';
import { OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';
import { PlatformModule } from '@app/platform.module';
import { AnalyticService, AuthService, NotifyService } from '@app/services';
import { LOCAL_STORAGE_KEYS } from '@app/services/local-storage/local-storage-key';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { RightNavService } from '@app/services/setup-guide/right-side-nav.service';
import { breakPoints } from '@app/styles/theme';
import { TranslateModule } from '@ngx-translate/core';
import { SetupGuideInitialDialogComponent } from '@time-off-v3/components/setup-guide-initial-dialog/setup-guide-initial-dialog.component';
import { TimeOffSidebarRedirectService } from '@time-off-v3/services/time-off-sidebar-redirect/time-off-sidebar-redirect.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SETUP_GUIDES_STEP_META } from '../../meta/time-off-setup-guides-step-meta';

@Component({
    selector: 'time-off-side-nav',
    templateUrl: 'time-off-side-nav.component.html',
    styleUrls: ['./time-off-side-nav.component.scss'],
    standalone: true,
    imports: [TranslateModule, PlatformModule, CommonModule, FormsModule, SetupGuideInitialDialogComponent],
})
export class TimeOffSideNavComponent implements OnInit, OnDestroy, AfterViewChecked {
    stepsCompleted = 0;
    totalSteps = 1;
    setupGuide: SetupGuide = new SetupGuide();
    setupGuideSteps: SetupGuideStep[] = [];

    setupGuideStepMeta = SETUP_GUIDES_STEP_META;

    routerSubscription!: Subscription;
    breakpointSubscription!: Subscription;

    stepOpenedState = [];

    showFinalAction = false;

    disableAnimations = true;

    constructor(
        public rightSideNav: RightNavService,
        private auth: AuthService,
        private analyticService: AnalyticService,
        private setupGuideService: SetupGuideService,
        private onboardingStatusService: OnboardingStatusService,
        private notify: NotifyService,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private localStorageService: LocalStorageService,
        private timeOffSidebarRedirectService: TimeOffSidebarRedirectService
    ) {}

    async ngOnInit(): Promise<void> {
        this.breakpointSubscription = this.breakpointObserver
            .observe(`(max-width: ${breakPoints.md}px)`)
            .subscribe(async (result) => {
                if ((await this.localStorageService.retrieve<boolean>(LOCAL_STORAGE_KEYS.rightNavBarClosed)) === true) {
                    return;
                }

                // hide if on mobile
                if (result.matches) {
                    this.rightSideNav.hide();
                    this.rightSideNav.close();
                    return;
                }

                this.rightSideNav.show();
                this.rightSideNav.open();
            });

        this.routerSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                // Only run on time off routes
                if (event.url.match(/time-off\/v3/)) {
                    this.refreshPanelContentVisiblity();
                }
            });

        this.rightSideNav.isLoading.next(true);

        const setupGuide = this.auth.company.setupGuides.find((guide) => {
            return guide.module.name === Platform.modules.timeOff;
        });

        if (setupGuide) {
            this.setupStates(setupGuide);
        }

        this.rightSideNav.isLoading.next(false);
    }

    ngAfterViewChecked(): void {
        /**
         * Used to prevent buggy initialization of mat-expansion-panel on load.
         * Fix found here: https://github.com/angular/components/issues/11765
         *
         * Some claim usage of @.disabled no longer works on newer versions of anuglar, but it still seems to work
         */
        this.disableAnimations = false;
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.breakpointSubscription.unsubscribe();
    }

    async openStep(stepKey: string): Promise<void> {
        const buttonTarget = this.setupGuideStepMeta[stepKey].buttonTarget;

        this.rightSideNav.isLoading.next(true);
        try {
            await this.timeOffSidebarRedirectService.redirectTo(buttonTarget);
        } catch (error) {
            this.notify.error(error);
        } finally {
            this.rightSideNav.isLoading.next(false);
        }

        this.stepOpenedState[stepKey] = true;
    }

    async refresh(): Promise<void> {
        this.rightSideNav.isLoading.next(true);

        try {
            await this.auth.refreshCompany();

            const setupGuide = this.auth.company.setupGuides.find((guide) => {
                return guide.module.name === Platform.modules.timeOff;
            });

            if (setupGuide) {
                this.setupStates(setupGuide);
            }
        } catch (error) {
            this.notify.error(error);
        } finally {
            this.rightSideNav.isLoading.next(false);
        }
    }

    async completeStep(setupGuideStep: SetupGuideStep): Promise<void> {
        try {
            this.analyticService.trackEvent(this.setupGuideStepMeta[setupGuideStep.stepKey].CTAEvent);

            await this.setupGuideService.stepComplete(setupGuideStep);
            this.refresh();

            // Refresh the quickstart for time off policies section.
            this.onboardingStatusService.refresh();
        } catch (error) {
            this.notify.error(error);
        }
    }

    async completeSetupGuide(): Promise<void> {
        this.rightSideNav.close();
        this.rightSideNav.hide();
        await this.setupGuideService.finalActionComplete(this.setupGuide);
        this.analyticService.trackEvent(AnalyticEvents.TimeOffSelfServeClickComplete);
        await this.auth.refreshCompany();
    }

    trackLearnMoreAboutTimeOff(): void {
        this.analyticService.trackEvent(AnalyticEvents.TimeOffSelfServeClickHelpCentre);
    }

    trackContactUs(): void {
        this.analyticService.trackEvent(AnalyticEvents.TimeOffSelfServeClickContactUs);
    }

    trackSetupGuideClosed(): void {
        this.analyticService.trackEvent(AnalyticEvents.TimeOffSelfServeCloseGuide);
    }

    private setupStates(setupGuide: SetupGuide): void {
        this.setupGuide = setupGuide;

        // If the side nav has been dismissed by the user never show it
        if (this.setupGuide.finalActionCompletedAt) {
            this.rightSideNav.close();
            this.rightSideNav.hide();
            return;
        }

        this.setupGuideSteps = setupGuide.setupGuideSteps;

        this.totalSteps = setupGuide.setupGuideSteps.length;

        this.stepsCompleted = setupGuide.setupGuideSteps.filter((setupGuideStep) => setupGuideStep.completedAt).length;

        this.showFinalAction = (this.stepsCompleted / this.totalSteps) * 100 === 100;

        this.refreshPanelContentVisiblity();
        this.refreshExtensionPanelExpandedState();
    }

    /**
     * Each mat-expansion-panel has content that is hidden unless you're on the right page.
     *
     * The right page is configured with SETUP_GUIDES_STEP_META.buttonTarget
     *
     */
    private refreshPanelContentVisiblity(): void {
        this.setupGuide.setupGuideSteps.forEach(async (step) => {
            let isStepActive = false;

            // TODO: could use with a cleanup with a more elegant extendable solution in the future
            if (this.setupGuideStepMeta[step.stepKey].buttonTarget === 'RedirectToLatestPolicyEnrolledEmployees') {
                // Although "lets go" will target latest policy
                // allow it to be in the revealed state on any enrolled employees page
                isStepActive = !!this.router.url.match(/\/time-off\/v3\/policy\/(\d)*\/enrolled-employees/);
            } else if (this.setupGuideStepMeta[step.stepKey].buttonTarget === 'RedirectToLatestWorkSchedule') {
                isStepActive = !!this.router.url.match(/\/time-off\/v3\/work-schedule\/(\d)*\/details/);
            } else {
                const url = await this.timeOffSidebarRedirectService.getRedirectionTarget(
                    this.setupGuideStepMeta[step.stepKey].buttonTarget
                );

                isStepActive = this.router.isActive(url[0], {
                    paths: 'exact',
                    matrixParams: 'ignored',
                    queryParams: 'ignored',
                    fragment: 'ignored',
                });
            }

            this.stepOpenedState[step.stepKey] = isStepActive;
        });
    }

    /**
     * Updates meta data which controls default expanded state of man-expansion-panel.
     */
    private refreshExtensionPanelExpandedState(): void {
        /**
         * Allows us to only expand the first step that isn't completed.
         */
        let latestStepExpanded = false;

        this.setupGuide.setupGuideSteps.forEach((step) => {
            this.setupGuideStepMeta[step.stepKey].expanded = false;

            if (!step.completedAt && !latestStepExpanded) {
                latestStepExpanded = true;
                this.setupGuideStepMeta[step.stepKey].expanded = true;
            }
        });
    }
}
