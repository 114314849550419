import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '@app/services/local-storage/local-storage-key';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticService } from '@app/services';

/**
 *
 * This service controls the visibility and toggling of the sidenav on right side of our app.
 * There are two observables which can be used to listen for state changes in the sidenav regarding
 * visibility/toggling.
 *
 *
 */
@Injectable({ providedIn: 'root' })
export class RightNavService {
    isOpen$: Observable<boolean>;
    isVisible$: Observable<boolean>;
    isLoading$: Observable<boolean>;

    public isLoading = new BehaviorSubject<boolean>(false);

    private isOpen = new BehaviorSubject<boolean>(false);
    private isVisible = new BehaviorSubject<boolean>(false);

    constructor(private localStorageService: LocalStorageService) {
        this.isOpen$ = this.isOpen.asObservable();
        this.isVisible$ = this.isVisible.asObservable();
        this.isLoading$ = this.isLoading.asObservable();
    }

    toggle(): void {
        this.isOpen.next(!this.isOpen.value);
    }

    open(): void {
        this.isOpen.next(true);
    }

    close(): void {
        this.isOpen.next(false);
    }

    show(): void {
        this.isVisible.next(true);
    }

    hide(): void {
        this.isVisible.next(false);
    }

    persistClose(): void {
        this.localStorageService.store(LOCAL_STORAGE_KEYS.rightNavBarClosed, true);
    }

    removePersistClose(): void {
        this.localStorageService.remove(LOCAL_STORAGE_KEYS.rightNavBarClosed);
    }
}
