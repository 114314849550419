import { AnalyticEvents } from '@app/enums';

export type UrlMapKeys =
    | 'RedirectToLatestPolicyEnrolledEmployees'
    | 'RedirectToLatestWorkSchedule'
    | 'RedirectToSetupTimeOffTypes'
    | 'RedirectToSetupTimeOffPolicies'
    | 'RedirectToImportTimeOffData';

/* eslint-disable camelcase */
type SetupGuideStepMeta = {
    name: string;
    buttonText: string;
    buttonTextCompleted: string;

    /**
     * RedirectToLatestPolicyEnrolledEmployees: has custom handling in TimeOffSideNavComponent when it comes to
     * URL route matching for step state and redirection handling for "lets go" action.
     */
    buttonTarget: UrlMapKeys;
    nestedButtonText: string;
    expanded: boolean;
    nestedSteps?: SetupGuideNestedStepGuide[];
    nestedDescription?: string;
    checkboxConsentText?: string;
    checkboxEnableNestedButton?: boolean;
    CTAEvent: AnalyticEvents;
};

type SetupGuideNestedStepGuide = {
    name: string;
    description: string;
    learnMoreLink: string;
    learnMoreLinkText: string;
};

export const SETUP_GUIDES_STEP_META: Record<string, SetupGuideStepMeta> = {
    setup_work_schedule: {
        name: 'time-off-v3.time-off-setup-guide.workScheduleTitle',
        expanded: true,
        buttonText: 'time-off-v3.time-off-setup-guide.letsDoItButton',
        buttonTextCompleted: 'time-off-v3.time-off-setup-guide.view',
        buttonTarget: 'RedirectToLatestWorkSchedule',
        nestedButtonText: 'time-off-v3.time-off-setup-guide.markAsDoneButton',
        nestedSteps: [
            {
                name: 'time-off-v3.time-off-setup-guide.workScheduleWeekendTitle',
                description: 'time-off-v3.time-off-setup-guide.workScheduleWeekendStepDescription',
                learnMoreLink:
                    'https://support.humi.ca/hc/en-us/articles/4409792231447-Set-up-and-assign-Work-Schedules#h_01HQV6GFDS1GCNNJ9Y67A4HNSH',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.workScheduleHolidayTitle',
                description: 'time-off-v3.time-off-setup-guide.workScheduleHolidayStepDescription',
                learnMoreLink:
                    'https://support.humi.ca/hc/en-us/articles/4409792231447-Set-up-and-assign-Work-Schedules#h_01HQV6GFDSVTQR49XNHCYSV8HH',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.workScheduleBlackoutPeriodTitle',
                description: 'time-off-v3.time-off-setup-guide.workScheduleBlackoutPeriodStepDescription',
                learnMoreLink:
                    'https://support.humi.ca/hc/en-us/articles/4409792231447-Set-up-and-assign-Work-Schedules#h_01HQV6GFDSW6FZ1XJER4GJ77FA',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
        ],
        CTAEvent: AnalyticEvents.TimeOffSSWorkScheduleClickDone,
    },

    setup_time_off_types: {
        name: 'time-off-v3.time-off-setup-guide.timeOffTypesTitle',
        expanded: false,
        buttonText: 'time-off-v3.time-off-setup-guide.letsDoItButton',
        buttonTextCompleted: 'time-off-v3.time-off-setup-guide.view',
        buttonTarget: 'RedirectToSetupTimeOffTypes',
        nestedButtonText: 'time-off-v3.time-off-setup-guide.markAsDoneButton',
        nestedSteps: [
            {
                name: 'time-off-v3.time-off-setup-guide.timeOffTypesNeedsTitle',
                description: 'time-off-v3.time-off-setup-guide.timeOffTypesNeedsDescription',
                learnMoreLink: 'https://support.humi.ca/hc/en-us/articles/4409791579671-How-to-set-up-time-off-Types',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.timeOffTypesPayrollTitle',
                description: 'time-off-v3.time-off-setup-guide.timeOffTypesPayrollDescription',
                learnMoreLink:
                    'https://support.humi.ca/hc/en-us/articles/19401674656023-How-to-Connect-Time-Off-to-Payroll',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
        ],
        CTAEvent: AnalyticEvents.TimeOffSSTimeOffTypeClickDone,
    },

    setup_time_off_policies: {
        name: 'time-off-v3.time-off-setup-guide.timeOffPoliciesTitle',
        expanded: false,
        buttonText: 'time-off-v3.time-off-setup-guide.letsDoItButton',
        buttonTextCompleted: 'time-off-v3.time-off-setup-guide.view',
        buttonTarget: 'RedirectToSetupTimeOffPolicies',
        nestedButtonText: 'time-off-v3.time-off-setup-guide.markAsDoneButton',
        nestedSteps: [
            {
                name: 'time-off-v3.time-off-setup-guide.timeOffPoliciesBasicInfoTitle',
                description: 'time-off-v3.time-off-setup-guide.timeOffPoliciesBasicInfoDescription',
                learnMoreLink:
                    'https://support.humi.ca/hc/en-us/articles/4409783080471-How-to-set-up-and-assign-Approval-Flows',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.timeOffTypesNeedsLearnMoreLinkText',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.timeOffPoliciesBalanceTitle',
                description: 'time-off-v3.time-off-setup-guide.timeOffPoliciesBalanceDescription',
                learnMoreLink:
                    'https://support.humi.ca/hc/en-us/articles/4409791610391-How-to-set-up-a-time-off-Policy',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.timeOffPoliciesTimeOffRulesTitle',
                description: 'time-off-v3.time-off-setup-guide.timeOffPoliciesTimeOffRulesDescription',
                learnMoreLink: 'https://support.humi.ca/hc/en-us/articles/21694950043287-Step-2-Accrual-Settings',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
        ],
        CTAEvent: AnalyticEvents.TimeOffSSTimeOffPolicyClickDone,
    },

    assign_time_off_policy: {
        name: 'time-off-v3.time-off-setup-guide.assignTimeOffPolicyTitle',
        expanded: false,
        buttonText: 'time-off-v3.time-off-setup-guide.letsDoItButton',
        buttonTextCompleted: 'time-off-v3.time-off-setup-guide.view',
        buttonTarget: 'RedirectToLatestPolicyEnrolledEmployees',
        nestedButtonText: 'time-off-v3.time-off-setup-guide.markAsDoneButton',
        nestedDescription: 'time-off-v3.time-off-setup-guide.assignTimeOffPolicyDescription',
        checkboxConsentText: 'time-off-v3.time-off-setup-guide.assignTimeOffPolicyConsentText',
        checkboxEnableNestedButton: false,
        CTAEvent: AnalyticEvents.TimeOffSSAssignPolicyClickDone,
    },

    import_time_off_data: {
        name: 'time-off-v3.time-off-setup-guide.importTimeOffTitle',
        expanded: false,
        buttonText: 'time-off-v3.time-off-setup-guide.letsDoItButton',
        buttonTextCompleted: 'time-off-v3.time-off-setup-guide.view',
        buttonTarget: 'RedirectToImportTimeOffData',
        nestedButtonText: 'time-off-v3.time-off-setup-guide.markAsDoneButton',
        nestedDescription: 'time-off-v3.time-off-setup-guide.importTimeOffDescription',
        nestedSteps: [
            {
                name: 'time-off-v3.time-off-setup-guide.importTimeOffBalancesTitle',
                description: 'time-off-v3.time-off-setup-guide.importTimeOffBalancesDescription',
                learnMoreLink: 'https://support.humi.ca/hc/en-us/articles/4410017891351-Importing-Time-Off-Balances',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.importTimeOffRequestsTitle',
                description: 'time-off-v3.time-off-setup-guide.importTimeOffRequestDescription',
                learnMoreLink: 'https://support.humi.ca/hc/en-us/articles/4410026348695-Importing-Time-Off-requests',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
            {
                name: 'time-off-v3.time-off-setup-guide.importTimeOffAdjustmentsTitle',
                description: 'time-off-v3.time-off-setup-guide.importTimeOffAdjustmentsDescription',
                learnMoreLink: 'https://support.humi.ca/hc/en-us/articles/4410017900951-Importing-Adjustments',
                learnMoreLinkText: 'time-off-v3.time-off-setup-guide.learnMore',
            },
        ],
        CTAEvent: AnalyticEvents.TimeOffSSImportDataClickDone,
    },
};
